import { useState, useEffect } from 'react'
import './LayoutOne.scss';
import './Select.scss';
import { Container, Row, Col } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import Popup from './Popup'
import PopupMessage from './PopupMessage'
import Select from 'react-select'
import { registerUser } from '../client-logic/register-user'
import { registerMemberMailchimp } from '../client-logic/register-member-mail'
import parse from 'html-react-parser'


function LayoutOne({ appJson : _appJson }) {

    const appJson = {
      ..._appJson,
      rows: _appJson.rows.map(r => {
        return { ...r, fields: r.fields.map(f => {
          return {
            ...f,
            "isMulti": f.isMulti || false,
            "key": f.key || "",
            "acceptanceKey": f.acceptanceKey || "",
            "required": f.required || false,
            "label": f.label || "",
            "help": f.help || "",
            "options": f.options || [],
            "metaHasOptions": f.metaHasOptions || false,
            "metaIsMulti": f.metaIsMulti || false,
            "metaOptions": f.metaOptions || [],
            "format": f.format || "",
            "validationMessage": f.validationMessage || "",
            "modalContent": f.modalContent || "",
            "modalButton": f.modalButton || ""
          };
        })}
      })
    }

    const [components, setComponents] = useState()
    const [backgroundColor, setBackgroundColor] = useState()
    const [isPopup, setIsPopup] = useState(false)
    const [isPopupMessage, setIsPopupMessage] = useState(false)
    const { register, handleSubmit, control } = useForm()
    const handleClose = () => {
        setIsPopupMessage(false)
        if (title !== 'Error') window.location.replace(appJson.redirectUrl)
    }
    const handleShow = () => setIsPopupMessage(true)
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')
    const [isSubmited, setIsSubmited] = useState(false)
    const [metaKey, setMetaKey] = useState()


    const colourStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: 'white',
            border: '0',
            boxShadow: 'none',
            minHeight: '3.2em',
            maxHeight: 'fit-conent',

        }),
        menu: styles => ({
            ...styles,
            backgroundColor: 'white',
        }),
        option: (styles, { isDisabled }) => {
            return {
                ...styles,
                backgroundColor: 'white',
                color: '#555555',
                textAlign: 'left',
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: 'white',
                },
                ':hover': {
                    ...styles[':hover'],
                    backgroundColor: 'rgba(85, 85, 85, 0.1)',
                },
            };
        },
        valueContainer: styles => {
            return {
                ...styles,
                padding: '1px'
            }
        },
        placeholder: styles => {
            return {
                ...styles,
                paddingBottom: '0px',
            };
        },

        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: 'white',
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#555555',
            padding: '2px',
            borderRadius: '4px 0px 0px 4px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#e6e6e6',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#555555',
            backgroundColor: '#e6e6e6',
            borderRadius: '0px 4px 4px 0px',
            width: '20px',
            ':hover': {
                width: '20px',
                backgroundColor: '#ffbdad',
                color: '#e55939',
            },
        }),
        input: (styles, { data}) => ({
            ...styles,

        }),
    };

    useEffect(() => {
        setIsSubmited(false)
    }, [])

    const onSubmit = async (formData) => {
        try {
            delete formData.fake
            delete formData.fakeAudience
            setIsSubmited(true)
            if (formData?.audiences?.length && typeof (formData.audiences) !== 'string') {
                const audiences = formData.audiences.map(item => item.key)
                formData.audiences = audiences
            }
            if (formData?.audiences?.length && typeof (formData.audiences) === 'string') formData.audiences = [formData.audiences]
            if (formData?.audiences?.length === 0) delete formData.audiences
            if (Object.keys(formData).includes('metaMulti')) {
                const metaMultis = formData.metaMulti.map(item => item.key)
                formData.meta[`${metaKey}`] = metaMultis
                delete formData.metaMulti
            }
            formData.appId = appJson.ubelongSlug
            console.log(formData)
            await registerUser(formData)
            setTitle(appJson.messages.signupCompletedTitle)
            setMessage(appJson.messages.signupCompletedMessage)
            handleShow()

            addMemberToMailchimp(formData)

        } catch (error) {
            setTitle('Error')
            const { response: { data: { message } } } = error
            switch (message) {
                case 'Invite code not found':
                    setMessage(appJson.messages.invalidCodeMessage)
                    break;
                case 'Invite code already used':
                  setMessage(appJson.messages.usedCodeMessage)
                  break;
                case 'email: value already exists.':
                    setMessage(appJson.messages.emailExistsMessage)
                    break;
                default:
                    setMessage(appJson.messages.unknownErrorMessage)
                    break;
            }
            setIsSubmited(false)
            handleShow()
        }
    }

    const addMemberToMailchimp = async (formData) => {
        let mergeFields = {}
        Object.keys(formData).forEach(key => {

            if (key === 'meta') {

                Object.keys(formData[`${key}`]).forEach(keyMeta => {
                    if(Array.isArray(formData[`${key}`][`${keyMeta}`])){
                        mergeFields[`${keyMeta.toUpperCase()}`] = formData[`${key}`][`${keyMeta}`].join(', ')
                    }else {
                        mergeFields[`${keyMeta.toUpperCase()}`] = formData[`${key}`][`${keyMeta}`]
                    }
                })
            } else if (key === 'audiences') {
                mergeFields[`${key.toUpperCase()}`] = formData[`${key}`][0]
            }
            else {
                if (formData[`${key}`] === true) formData[`${key}`] = 'true'
                if (formData[`${key}`] === false) formData[`${key}`] = 'false'
                mergeFields[`${key.toUpperCase()}`] = formData[`${key}`]
            }
        })
        const { mailchimpApiKey, mailchimpServer, mailchimpListId, mailchimpEnable } = appJson
        try {
            delete mergeFields.PASSWORD
            delete mergeFields.APPID
            delete mergeFields[0]
            if (mailchimpEnable) {
                const res = await registerMemberMailchimp(
                {
                    apiKey: mailchimpApiKey,
                    server: mailchimpServer,
                    mailchimpId: mailchimpListId,
                    email: formData.email,
                    status: 'subscribed',
                    mergeFields: mergeFields
                })
                console.log("Maichimp response", res);
            }
        } catch (error) {
            console.log("Maichimp error", error);
        }
    }

    const hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            }
            : null;
    };

    useEffect(() => {
        setComponents(hexToRgb('#ffffff'))
    }, [])
    useEffect(() => {
        if (components) setBackgroundColor(`rgba(${components.r},${components.g},${components.b},0.3)`)
    }, [components])



    return (
        <div className='layout-one'
            style={{
                backgroundColor: appJson.themeColor,
                backgroundImage: `url(${appJson.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>

            <Container>
                <Row>
                    <Col className='layout-one-col layout-one-col-logo' md={{ span: 4, offset: 4 }}>
                        {appJson?.logo?.length > 0 && <img srcSet={appJson.logo} src={`${appJson.logo}`} alt='logo' />}
                    </Col>
                </Row>
                <Row>
                    <Col className='layout-one-col layout-one-col-title' md={{ span: 4, offset: 4 }}>
                        {appJson.title}
                    </Col>
                </Row>
                <Row>
                    <Col className='layout-one-col layout-one-col-subtitle' md={{ span: 4, offset: 4 }}>{appJson.subtitle}</Col>
                </Row>
                {
                    appJson.topHtmlBeforeForm &&
                    <Row>
                        <Col className='layout-one-col layout-one-col-html-top' md={{ span: 12, offset: 0 }}>{parse(appJson.topHtmlBeforeForm)}</Col>
                    </Row>
                }

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row >
                        <Col style={{ backgroundColor: 'white' }}
                            className='layout-one-col-wide layout-one-col-inputs'
                            md={{ span: appJson.wideMode ? 8 : 6, offset: appJson.wideMode ? 2 : 3 }}
                            lg={{ span: appJson.wideMode ? 8 : 4, offset: appJson.wideMode ? 2 : 4 }}
                            sm={{ span: appJson.wideMode ? 10 : 8, offset: appJson.wideMode ? 1 : 2 }}
                            xs={{ span: appJson.wideMode ? 10 : 10, offset: appJson.wideMode ? 1 : 1 }}
                        >
                            {
                                appJson.rows.map(row =>
                                    <div className={'layout-one-col-wide-border'}>

                                        {
                                            row.fields.map((field, i) =>

                                                field._template === 'audience' ?
                                                    !field.isMulti ?
                                                        <>
                                                            {i === 1 ? <div style={{
                                                                width: '1px',
                                                                height: '35px',
                                                                backgroundColor: '#e9e9e9',
                                                                marginTop: '10px'
                                                            }}></div> : ''}
                                                            <select
                                                                className={
                                                                    i === 1 ?
                                                                        'layout-one-col-wide-select layout-one-col-wide-select-border-left' :
                                                                        'layout-one-col-wide-select'}
                                                                style={{
                                                                    width: row.fields.length > 1 ? '50%' : '100%',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    paddingRight: '35px'
                                                                }}
                                                                name={field.label}
                                                                {...register('audiences')}
                                                                required={field.required}
                                                                onInput={({ target }) => {
                                                                    !target.value.length ?
                                                                        target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                        target.setCustomValidity('')
                                                                }}
                                                                onInvalid={({ target }) => {
                                                                    target.setCustomValidity(appJson.messages.requiredFieldMessage)
                                                                }}
                                                            >
                                                                <option value="" disabled selected >{field.required ?
                                                                    `${field.label} *` :
                                                                    `${field.label}`}</option>
                                                                {
                                                                    field.options.map(option =>
                                                                        <option value={option.key}>{option.label}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </> :
                                                        <>
                                                            {i === 1 ? <div style={{
                                                                width: '1px',
                                                                height: '35px',
                                                                backgroundColor: '#e9e9e9',
                                                                marginTop: '10px'
                                                            }}></div> : ''}
                                                            <Controller
                                                                name={'audiences'}
                                                                control={control}
                                                                rules={{ required: field.required }}
                                                                render={({ field: fieldSelect }) => {
                                                                    return <Select
                                                                        {...fieldSelect}
                                                                        placeholder={field.required ?
                                                                            `${field.label} *` :
                                                                            `${field.label}`}
                                                                        className={row.fields.length > 1 ? 'select-multi' : 'select-multi-wide'}
                                                                        styles={colourStyles}
                                                                        options={field.options}
                                                                        getOptionValue={(option) => `${option['key']}`}
                                                                        isMulti={true}
                                                                        // onMenuOpen={() => setMetaKey(field.key)}
                                                                        onInputChange={(e) => {
                                                                            if (field.required) {
                                                                                document.getElementById(`hidden-required-${field._template}`).value = "My value"
                                                                                document.getElementById(`hidden-required-${field._template}`).setCustomValidity('')
                                                                            }
                                                                        }}

                                                                    />
                                                                }}
                                                            />
                                                            {
                                                                field.required && field?.isMulti &&
                                                                <input
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: "0px",
                                                                        height: '62px',
                                                                        position: "absolute",
                                                                        left: appJson.wideMode ? '65%' : '40%'
                                                                    }}
                                                                    tabIndex={-1}
                                                                    id={`hidden-required-${field._template}`}
                                                                    name={field._template}
                                                                    type='text'
                                                                    pattern={field.format.length ? field.format : '^.{1,50}$'}
                                                                    required={field.required}
                                                                    {...register('fakeAudience')}
                                                                    onInput={({ target }) => {
                                                                        !target.value.length ?
                                                                            target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                            target.setCustomValidity('')
                                                                    }}
                                                                    onInvalid={({ target }) => {
                                                                        !target.value.length ?
                                                                            target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                            target.setCustomValidity('')
                                                                    }}



                                                                />
                                                            }
                                                        </>
                                                    :
                                                    field._template !== 'acceptance' && !field.metaHasOptions ?
                                                        <>
                                                            {i === 1 ? <div style={{
                                                                width: '1px',
                                                                height: '35px',
                                                                backgroundColor: '#e9e9e9',
                                                                marginTop: '10px'
                                                            }}></div> : ''}
                                                            <input className='layout-one-col-semiborder'
                                                                style={{
                                                                    width: row.fields.length > 1 ? '50%' : '100%',
                                                                    // borderLeft: i === 1 ? '1px solid #e9e9e9' : '0',
                                                                    paddingLeft: i === 1 ? '0.4em' : '0.25em',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    paddingRight: '0px'
                                                                }}
                                                                pattern={field.format.length ? field.format : '^.{1,500}$'}
                                                                type={field._template === 'password' ? 'password' : 'text'}
                                                                {...register(field._template === 'meta' ? `meta.${field.key}` : field._template)}
                                                                required={field.required}
                                                                placeholder={field.required ? `${field.label} *` : field.label}
                                                                onInvalid={({ target }) => {
                                                                    target.validity.patternMismatch ?
                                                                        target.setCustomValidity(field.validationMessage) :
                                                                        target.value.length === 0 ?
                                                                            target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                            target.setCustomValidity('')
                                                                }}
                                                                onInput={({ target }) => {
                                                                    !target.value.length && field.required ?
                                                                        target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                        target.setCustomValidity('')
                                                                }}
                                                            />
                                                        </> :

                                                        field.metaHasOptions ?
                                                            !field.metaIsMulti ?
                                                                <>
                                                                    {i === 1 ? <div style={{
                                                                        width: '1px',
                                                                        height: '35px',
                                                                        backgroundColor: '#e9e9e9',
                                                                        marginTop: '10px'
                                                                    }}></div> : ''}
                                                                    <select
                                                                        className={
                                                                            i === 1 ?
                                                                                'layout-one-col-wide-select layout-one-col-wide-select-border-left' :
                                                                                'layout-one-col-wide-select'}
                                                                        style={{
                                                                            width: row.fields.length > 1 ? '50%' : '100%',
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'nowrap',
                                                                            textOverflow: 'ellipsis',
                                                                            paddingRight: '38px'
                                                                        }}
                                                                        name={field.label}
                                                                        {...register(field._template === 'meta' ? `meta.${field.key}` : field._template)}
                                                                        required={field.required}
                                                                        onInput={({ target }) => {
                                                                            !target.value.length ?
                                                                                target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                                target.setCustomValidity('')
                                                                        }}
                                                                        onInvalid={({ target }) => {
                                                                            target.setCustomValidity(appJson.messages.requiredFieldMessage)
                                                                        }}
                                                                    >
                                                                        <option value="" disabled selected >{field.required ?
                                                                            `${field.label} *` :
                                                                            `${field.label}`}</option>
                                                                        {
                                                                            field.metaOptions.map(option =>
                                                                                <option value={option.key}>{option.label}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </> :
                                                                <>
                                                                    {i === 1 ? <div style={{
                                                                        width: '1px',
                                                                        height: '35px',
                                                                        backgroundColor: '#e9e9e9',
                                                                        marginTop: '10px'
                                                                    }}></div> : ''}
                                                                    <Controller
                                                                        name={`metaMulti`}
                                                                        control={control}
                                                                        rules={{ required: field.required }}
                                                                        render={({ field: fieldSelect }) => {
                                                                            return <>
                                                                                <Select
                                                                                    id='multi-select'
                                                                                    placeholder={field.required ?
                                                                                        `${field.label} *` :
                                                                                        `${field.label}`}
                                                                                    {...fieldSelect}
                                                                                    className={row.fields.length > 1 ? 'select-multi' : 'select-multi-wide'}
                                                                                    styles={colourStyles}
                                                                                    options={field.metaOptions.map((o, i) => ({ label: o.label, key: o.label }))}
                                                                                    isMulti={true}
                                                                                    onMenuOpen={() => setMetaKey(field.key)}
                                                                                    getOptionValue={(option) => `${option['key']}`}
                                                                                    onInputChange={(e) => {
                                                                                        if(field.required){
                                                                                            document.getElementById(`hidden-required-meta-${field.key}`).value = "My value"
                                                                                            document.getElementById(`hidden-required-meta-${field.key}`).setCustomValidity('')
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        }}
                                                                    />
                                                                    {
                                                                        field.required && field?.metaIsMulti &&
                                                                        <input
                                                                            style={{
                                                                                opacity: 0,
                                                                                width: "0px",
                                                                                height: '62px',
                                                                                position: "absolute",
                                                                                left: appJson.wideMode ? '40%' : '40%'
                                                                            }}
                                                                            tabIndex={-1}
                                                                            id={`hidden-required-meta-${field.key}`}
                                                                            name={field.key}
                                                                            type='text'
                                                                            pattern={field.format.length ? field.format : '^.{1,50}$'}
                                                                            required={field.required}
                                                                            {...register('fake')}
                                                                            onInput={({ target }) => {
                                                                                !target.value.length ?
                                                                                    target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                                    target.setCustomValidity('')
                                                                            }}
                                                                            onInvalid={({ target }) => {
                                                                                !target.value.length ?
                                                                                    target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                                    target.setCustomValidity('')
                                                                            }}



                                                                        />
                                                                    }
                                                                </>
                                                            :
                                                            ''
                                            )
                                        }
                                    </div>
                                )
                            }
                        </Col>
                    </Row>

                    <Row >
                        <Col
                            className='layout-one-col-wide layout-one-col-inputs'
                            md={{ span: appJson.wideMode ? 8 : 6, offset: appJson.wideMode ? 2 : 3 }}
                            // lg={{ span: appJson.wideMode ? 8 : 4, offset: appJson.wideMode ? 2 : 4 }}
                            sm={{ span: appJson.wideMode ? 10 : 8, offset: appJson.wideMode ? 1 : 2 }}
                            xs={{ span: appJson.wideMode ? 10 : 10, offset: appJson.wideMode ? 1 : 1 }}
                        >
                            {
                                appJson.rows.map(row =>
                                    <div>
                                        {
                                            row.fields.map((field, i) =>
                                                field._template === 'acceptance' ?
                                                    <div className='layout-one-col-wide-checkbox'>
                                                            <input
                                                                {...register(field.acceptanceKey.length ? field.acceptanceKey : `${i}`)}
                                                                required={field.required}
                                                                type='checkbox'
                                                                onInput={({ target }) => {
                                                                    !target.checked && field.required ?
                                                                        target.setCustomValidity(appJson.messages.requiredFieldMessage) :
                                                                        target.setCustomValidity('')
                                                                }}
                                                                onInvalid={({ target }) => {
                                                                    target.setCustomValidity(appJson.messages.requiredFieldMessage)
                                                                }}
                                                            />
                                                        <label>
                                                            {field.label}
                                                            <span
                                                                className={'layout-one-col-wide-checkbox-underline'}
                                                                onClick={() => setIsPopup(true)}>
                                                                {
                                                                    `${field.modalButton}`}
                                                            </span>
                                                            {field.modalButton && field.required && '*'}
                                                        </label>
                                                        {/* <span >{field.required && ` *`}</span> */}
                                                        <Popup show={isPopup} size={'lg'} onHide={() => setIsPopup(false)} content={field.modalContent} />
                                                    </div>
                                                    : ''
                                            )
                                        }
                                    </div>
                                )
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col className='layout-one-col layout-one-col-button'
                            md={{ span: 6, offset: 3 }}
                            lg={{ span: 4, offset: 4 }}
                            sm={{ span: 8, offset: 2 }}
                            xs={{ span: 10, offset: 1 }}
                        >

                            {
                                components && backgroundColor &&
                                <input
                                    disabled={isSubmited}
                                    type='submit'
                                    value={appJson.registerButton}
                                    style={{
                                        backgroundColor: backgroundColor,
                                        marginBottom: '2em'
                                    }}
                                    onMouseEnter={() => {
                                        setBackgroundColor(`rgba(${components.r},${components.g},${components.b},0.8) !important`)
                                    }}
                                    onMouseLeave={() => {
                                        setBackgroundColor(`rgba(${components.r},${components.g},${components.b},0.3) !important`)
                                    }}
                                />
                            }
                        </Col>
                    </Row>
                    {
                        // errorMessage?.length &&
                        <PopupMessage
                        show={isPopupMessage}
                        handleClose={handleClose}
                        setIsPopupMessage={setIsPopupMessage}
                        onHide={() => setIsPopupMessage(false)}
                        buttonColor={appJson.themeColor}
                        message={message}
                        title={title}
                        />
                    }
                </form>
                        {
                            appJson.bottomHtmlAfterButton &&
                            <div className='layout-one-col-html-bottom'>
                                {parse(appJson.bottomHtmlAfterButton)}
                            </div>
                        }
            </Container>
        </div>
    )
}

export default LayoutOne;
