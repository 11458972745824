import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Main from './App/Components/Main'


function App() {
  
  return (
    <Router>
      <Switch>
        <Route path='/:route' children={<Main />}/>
      </Switch>
    </Router>
  );
}

export default App;
