import './LayoutThree.scss';
// import { Container, Row, Col, Button } from 'react-bootstrap'

function LayoutThree({ appJson }) {


    return (
        <div className='layout-three' style={{ backgroundColor: appJson.themeColor }}>
            <p>Hello World! 3</p>
        </div>
    )
}

export default LayoutThree;
