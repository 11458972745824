import { Modal, Button } from 'react-bootstrap'
import './Popup.scss'

function PopupMessage({ show, handleClose, buttonColor, message, title }) {



    return (
        <div className='popup-message'>
            <Modal centered show={show} onHide={handleClose} animation={false}>
                <Modal.Header style={{border: 0}} closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{color: '#747d85'}}>{message}</Modal.Body>
                <Modal.Footer style={{border: 0}} >
                    <Button style={{
                        backgroundColor: buttonColor,
                        border: 0
                    }}
                        variant="secondary"
                        onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PopupMessage
