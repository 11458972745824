import {Container,/* Col, Row,*/ Modal} from 'react-bootstrap'
import parse from 'html-react-parser'


function Popup(props) {

    return (
        <Modal scrollable size={props.size} {...props} aria-labelledby="contained-modal-title-vcenter" >
        <Modal.Header closeButton style={{ borderBottom: 0}}>
      
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {/* <Row> */}
              {/* <Col xs={12} md={10} > */}
                {parse(`${props.content}`)}
              {/* </Col> */}
            {/* </Row> */}
          </Container>
        </Modal.Body>
      </Modal>
    );
}

export default Popup;
