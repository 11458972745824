import './Main.scss';
import { useParams } from 'react-router-dom'
import LayoutOne from './LayoutOne'
import LayoutTwo from './LayoutTwo';
import LayoutThree from './LayoutThree'
import { useState, useEffect } from 'react'
import apps from '../../apps.json'

function Main() {

    const [appJson, setAppJson] = useState()
    const { route } = useParams()

    useEffect(() => {
        apps.apps.forEach(app => {
            app.slug === route && setAppJson(app)
        })
    }, [route])

    return (
        (appJson && appJson.layout === 'layout1') ? <LayoutOne appJson={appJson}/> : 
        (appJson && appJson.layout === 'layout2') ? <LayoutTwo appJson={appJson}/> : 
        (appJson && appJson.layout === 'layout3') ? <LayoutThree appJson={appJson}/> : ''
    );
}

export default Main;
